import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';

import { catchError, map, Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { HomeApiService } from './home-api.service';
import { Journal } from '../interfaces/journals.interface';
import { Category } from '../interfaces/category.interface';
import { Assessment } from '../interfaces/assessment.interface';
import { PeriodCycle } from '../interfaces/period-cycle.interface';
import { Trainer } from '../interfaces/trainers.interface';
import { Video } from '../interfaces/videos.interface';
import { Reminder } from '../interfaces/reminders.interface';
import { Package } from '../interfaces/packages.interface';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HomeSignalsService {
  private urlSegments: WritableSignal<string[]> = signal<string[]>([]);
  public isSubscribed: WritableSignal<boolean> = signal<boolean>(false);
  public journals: WritableSignal<Journal[]> = signal<Journal[]>([]);
  public categories: WritableSignal<Category[]> = signal<Category[]>([]);
  public assessments: WritableSignal<Assessment[]> = signal<Assessment[]>([]);
  public trainers: WritableSignal<Trainer[]> = signal<Trainer[]>([]);
  public videos: WritableSignal<Video[]> = signal<Video[]>([]);
  public reminders: WritableSignal<Reminder[]> = signal<Reminder[]>([]);
  public packages: WritableSignal<Package[]> = signal<Package[]>([]);
  public periodCyclesResponse: WritableSignal<PeriodCycle[]> = signal<
    PeriodCycle[]
  >([]);

  public urlSegmentsSignal: Signal<string[]> = computed(() =>
    this.urlSegments()
  );

  public currentCategoryAssessments: Signal<Assessment[]> = computed(() =>
    this.getCurrentCategoryAssessments()
  );

  private readonly signalMap = {
    journals: this.journals,
    categories: this.categories,
    assessments: this.assessments,
    trainers: this.trainers,
    videos: this.videos,
    packages: this.packages,
    periodCycles: this.periodCyclesResponse,
    reminders: this.reminders,
  };

  constructor(private readonly homeApiService: HomeApiService) {}

  public setIsSubscribed(): void {
    this.homeApiService
      .getUserProfile()
      .pipe(
        untilDestroyed(this),
        map((response) => response.responseData.userProfile.isSubscribed)
      )
      .subscribe((isSubscribed) => this.isSubscribed.set(isSubscribed));
  }

  private getCurrentCategoryAssessments(): Assessment[] {
    const parentCategory = this.categories().find(
      (category) =>
        category.name === 'Assessment' &&
        category.parent?.name === this.urlSegmentsSignal()[1]
    );

    return this.assessments().filter(
      (assessment) =>
        assessment.category.parentId === parentCategory?.parent?._id
    );
  }

  public initiateSubscription<T, R>(
    apiCall: () => Observable<R>,
    dataExtractor: (response: R) => T,
    signalKey: keyof typeof this.signalMap
  ): void {
    apiCall()
      .pipe(
        untilDestroyed(this),
        map(dataExtractor),
        catchError((_) => of(null as T))
      )
      .subscribe((data: any) => {
        !!data && this.signalMap[signalKey].set(data);
      });
  }

  public getSelectedTrainer(route: string): Trainer | undefined {
    return this.trainers().find((trainer) => trainer._id === route);
  }

  public getCategoryByName = (
    categoryTitle: string,
    restrictToRootCategories: boolean = true
  ): Category | undefined =>
    this.categories()
      .filter((category) =>
        restrictToRootCategories ? !category.parent : category
      )
      .find((category) => category.name === categoryTitle);

  public setURLSegments(urlSegments: string[]): void {
    this.urlSegments.set(urlSegments);
  }
}
