export const MONITORED_ROUTES = {
  assessments: {
    root: 'assessments',
    list: 'assessments/list1',
  },
  videos: {
    root: 'videos',
    list: 'videos/list1',
  },
  trainers: {
    root: 'trainers',
    list: 'trainers/list1',
  },
  questions: {
    root: 'questions',
    list: 'questions/list',
  },
  questionsUsers: {
    root: 'questionsUsers',
    list: 'questionsUsers/list',
  },
  journal: {
    root: 'journal',
    list: 'journal/list',
  },
  cyclePeriod: {
    root: 'cyclePeriod',
    list: 'cyclePeriod/list',
    getCyclePeriod: 'cyclePeriod/getCyclePeriod',
  },
  reminders: {
    root: 'reminders',
    list: 'reminders/list',
  },
};

export const UNMONITORED_ROUTES = {
  categories: {
    root: 'categories',
    list: 'categories/list'
  },
  packages: {
    root: 'packages',
    list: 'packages/list'
  },
  payments: 'payments'
}

export const HOME_ENDPOINTS = {
  ...MONITORED_ROUTES,
  ...UNMONITORED_ROUTES,
};
